// ==============================
// Custom style
// 自定义样式
// ==============================
.home {
    .home-profile {
      .home-avatar {
        img {
          width: 15rem;
        }
      }
    }
  }